.textos1 {
  font-weight: bold !important;
}

.imagem {
  background-image: url(https://i.imgur.com/koFgtwU.png);
  background-repeat: no-repeat;
  width: 80vh;
  min-height: 80vh;
  background-size: cover;
  background-position: center;
  margin-top: 10px !important;
}

.text-decorator-none {
  text-decoration: none;
}

.button {
  background-color: #DF8686 !important;
  box-shadow: 0 0 3px black !important;
}
