.imagem2 {
  background-image: url(https://i.imgur.com/L7f3UNV.png);
  background-repeat: no-repeat;
  width: 80vh;
  min-height: 80vh;
  background-size: cover;
  background-position: center;
  margin-top: 10px !important;
}

.textos2 {
  font-weight: bold !important;
}

.btnCancelar {
  margin-right: 20px !important;
  margin-bottom: 10px !important;
}

.btnCadastrar {
  margin-bottom: 10px !important;
}
