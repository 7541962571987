.caixa {
  background-color: #d04343;
}

.titulo {
  color: white !important;
  font-weight: bold !important;
  font-family: Segoe UI !important;
}

.botao {
  border-color: white !important;
  color: white !important;
  font-family: Segoe UI !important;
}

.postagens {
  background-color: white;
}
